export default {
    l_User_name: "Қолданушы аты",
    l_User_password: "Қолданушы құпия сөзі",
    l_Forgot_password: "Құпия сөзді қалпына келтіру",
    l_Login: "Кіру",
    l_Sign_out: "Шығу",
    l_EmailorPasswordisWrong: "Электрондық пошта немесе құпия сөз қате",
    l_User: "Қолданушы",
    l_Payments: "Төлемдер",
    l_Promocodes: "Промокоды",
    l_Messages: "Хабарламалар",
    l_Consolidations: "Біріктірулер",
    l_Courier_Orders: "Курьердік тапсырыстар",
    l_Almaty: "Алматы",
    l_Name: "Аты",
    l_Add: "Қосу",
    l_Action: "Әрекет",
    l_Update: "Жаңарту",
    l_Edit: "Өңдеу",
    l_Submit: "Жіберу",
    l_Cancel: "Болдырмау",
    l_Are_you_sure_delete_this_task: "Бұл тапсырманы жойғыңыз келе ме?",
    l_Yes: "Иә",
    l_No: "Жоқ",
    l_Tracking_number: "Бақылау нөмірі",
    l_Internal_Tracking_number: "Ішкі бақылау нөмірі",
    l_Actual_Price: "Нақты баға",
    l_Estimated_price: "Болжалды баға",
    l_Actual_weight: "Нақты салмақ",
    l_kg: "кг",
    l_Estimated_weight: "Есептік салмақ",
    l_Delivery_country: "Жеткізу елі",
    l_Delivery_type: "Жеткізу түрі",
    l_Address: "Мекенжай",
    l_Execution_status: "Орындалу статусы",
    l_Cargo: "Тауар",
    l_Sign_up: "Тіркелу",
    l_Next: "Келесі",
    l_Verify_code: "Кодты тексеру",
    l_Set_password: "Құпия сөзді орнату",
    l_Confirm_password: "Құпия сөзді растау",
    l_Get_SMS: "SMS алу",
    l_Get_the_verification_code_first: "Алдымен растау кодын алыңыз",
    l_Confirm_password_error: "Құпия сөзді растау қатесі",
    l_Phone_number: "Телефон нөмірі",
    l_Password: "Құпия сөз",
    l_Wrong_password_format: "Құпия сөз форматы қате",
    l_Please_enter_phone_number: "Телефон нөмірді енгізіңіз",
    l_Please_enter_sms: "SMS енгізіңіз",
    l_My_addresses: "Мекенжайларым",
    l_My_parcels: "Cәлемдемелерім",
    l_Buy_instead_of_me: "Сіз үшін сатып аламыз",
    l_Personal_data: "Жеке деректер",
    l_Update_password: "Құпия сөзді жаңарту",
    l_Old_Password: "Ескі құпия сөз",
    l_New_Password: "Жаңа құпия сөз",
    l_Successed: "Сәтті",
    l_Personal_information: "Жеке ақпарат",
    l_User_list: "Қолданушылар тізімі",
    l_Email: "Электрондық пошта",
    l_Add_user: "Қолданушыны қосу",
    l_Full_name: "Аты-жөні",
    l_IIN: "ЖСН",
    l_ID_number: "Жеке куәлік нөмірі",
    l_Issuing_authority: "Берген орган",
    l_Issue_date: "Берілген күні",
    l_Validity: "Қолданылу мерзімі",
    l_Delivery_address: "Жеткізу мекенжайы",
    l_Town: "Облыс-Қала/Аудан",
    l_Street: "Көше",
    l_House: "Үй",
    l_Postcode: "Пошта индексі",
    l_Identity_card: "Жеке куәлік",
    l_Facial: "Беттік жағы",
    l_Negotiable: "Артқы жағы",
    l_Draw_a_signature: "Қолтаңбаны салу",
    l_Or_download_the_signed_contract: "Немесе қол қойылған келісімшартты жүктеп қосыңыз",
    l_Note: "Ескертпелер",
    l_Precautions_for_uploading_pictures: "Қосылған құжаттың сапасы жақсы болуы керек. Сурет 1 МБ-тан аспауы керек.",
    l_Click_or_drag_ile_to_this_area_to_upload: "Файлды жүктеп қосу үшін осы аймаққа басыңыз немесе файлды әкеліп қойыңыз",
    l_My_order: "Тапсырысым",
    l_Track_code: "Трек-код",
    l_Recipient: "Алушы",
    l_Cargo_name: "Жүк атауы",
    l_Declared_price: "Жарияланған баға",
    l_Date: "Күн",
    l_Delivery_cost: "Жеткізу құны",
    l_Dispatch_country: "Жіберу елі",
    l_Delivery_method: "Жеткізу әдісі",
    l_Order: "Тапсырыс",
    l_Recipient_data: "Алушының деректері",
    l_Shipping_country: "Жеткізу елі",
    l_Transportation_method: "Тасымалдау әдісі",
    l_Price: "Баға",
    l_Quantity: "Саны",
    l_Total_cost: "Жалпы құн",
    l_Category: "Категория",
    l_Weapons_and_explosive_materials: "Қару және жарылғыш материалдар",
    l_Alcoholic_drinks: "Алкогольдік сусындар",
    l_Sprays_and_aerosols: "Спреи және аэрозольдер",
    l_Medicines_without_a_doctors_prescription: "Дәрігердің рецептісіз дәрі-дәрмектер",
    l_radioactive_elements: "Радиоактивті элементтер",
    l_Animals_and_plants: "Жануарлар мен өсімдіктер",
    l_ALL_RIGHTS_RESERVED: "БАРЛЫҚ ҚҰҚЫҚТАР САҚТАЛҒАН",
    l_Contact: "Контакт",
    l_About_us: "Біз туралы",
    l_The_shops: "Дүкендер",
    l_How_it_works: "Бұл қалай жұмыс істейді?",
    l_Rates: "Тарифтер",
    l_Frequently_asked_Questions: "Жиі қойылатын сұрақтар",
    l_Prohibited_Items: "Тыйым салынған заттар",
    l_Privacy_Policy: "Құпиялылық саясаты",
    l_Weight: "Салмақ",
    l_Declaration: "Декларация",
    l_Pay: "Төлеу",
    l_Provinces: "Аудандар",
    l_Country: "Мемлекет",
    l_Full_address: "Толық мекенжай",
    l_MY_ADDRESS_IN_CHINA_FOR_AUTO_DELIVERY: "КӨЛІКПЕН ЖЕТКІЗУ ҮШІН ҚЫТАЙДАҒЫ МЕКЕНЖАЙ",
    l_Add_product: "Тауар қосу",
    l_Please_enter_quantity: "Санын енгізіңіз",
    l_The_maximum_number_is: "Ең көп сан",
    l_China: "Қытай",
    l_Auto_delivery: "Автожеткізу",
    l_To_avoid_problems_during_customs_clearance_please_enter_a_detailed_description_of_the_product_name_in_Russian: "Кедендік тазалау кезінде мәселердің алдын алу үшін тауардың атауының орыс тілінде нақты сипаттамасын енгізуді сұраймыз.",
    l_Later_confirm: "Кейінірек растау",
    l_Confirm: "Растау",
    l_Succeed: "Операция сәтті өтті",
    l_Provide_a_link_to_the_product_we_will_buy_and_bring_it_for_you: "Тауардың сілтемесін жіберіңіз, біз оны сіз үшін сатып алып әкелеміз!",
    l_Cargo_link: "Тауар сілтемесі",
    l_Additional_comment: "Қосымша түсініктеме",
    l_Are_you_willing_to_buy_if_the_price_fluctuates_up_to_7: "Егер баға 7% -ға дейін ауытқыса, сіз сатып алуға дайынсыз ба?",
    l_size: "Өлшем",
    l_Color: "Түс",
    l_Delivery_price: "Жеткізу бағасы",
    l_Promo_codes: "Промокодтар",
    l_Whether_the_payment_is_completed: "Төлем аяқталды ма?",
    l_Completed: "Аяқталды",
    l_Not_completed: "Аяқталмаған",
    l_Image_must_smaller_than_3MB: "Сурет 3 МБ-тан аз болуы керек!",
    l_Image_must_smaller_than_2MB: "Сурет 2 МБ-тан аз болуы керек!",
    l_Image_format_error: "Суреттің форматы қате!",

    l_Analytics: "Аналитика",
    l_Status: "Статус",
    l_Order_id: "Тапсырыс идентификаторы",
    l_Traking_number: "Трэк код",
    l_Amount: "Сома",
    l_Payment_metehod: "Төлем әдісі",
    l_Payment: "Төлем",
    l_Recipients: "Алушылар",
    l_First_name: "Аты-жөні",
    l_Last_name: "Тегі",
    l_Type: "Типі",
    l_Client: "Клиент",
    l_Admin: "Админ",
    l_Last_login: "Соңғы кіру",
    l_Register: "Тіркелу",
    l_Verified: "Тексерілген",
    l_Not_verified: "Тексеруден өтпеді",
    l_All: "Барлығы",
    l_Search: "Іздеу",
    l_Statuses: "Күйлер",
    l_Start_country: "Шығатын ел",
    l_End_country: "Жететін ел",
    l_Sort: "Сұрыптау",
    l_Add_time: "Уақыт қосу",
    l_Update_time: "Өзгертілген уақыт",
    l_Ascending: "Өсу ретімен",
    l_Descending: "Кему ретімен",
    l_Create_time: "Құрылған уақыт",
    l_Message: "Хабарлама",
    l_Send_message: "Хабарлама жіберу",
    l_Internal_tracking_number: "Ішкі бақылау нөмірі",
    l_Shopping_instead: "Орнына сатып алу",
    l_Size: "Өлшем",
    l_Description: "Сипаттамасы",
    l_Link: "Сілтеме",
    l_City: "Қала",
    l_All_cargo: "Барлық тауарлар",
    l_Volume: "Көлемі",
    l_Height: "Биіктік",
    l_Paid: "Төленген",
    l_Unpaid: "Төленбеген",
    l_Declaration_verify: "Декларацияны тексеру",
    l_Update_date: "Жаңарту күні",
    l_Add_date: "Күн қосу",
    l_Add_to_consolidation: "Біріктіруге қосу",
    l_Consolidation: "Біріктіру",
    l_Consolidation_id: "Біріктіру ID",
    l_Add_type: "Түрді қосу",
    l_Add_new: "Жаңа қосу",
    l_Contractor: "Мердігер",
    l_Selected_order_quantity: "Таңдалған тапсырыс саны",
    l_Flight_number: "Рейс нөмірі",
    l_Execution_date: "Орындау күні",
    l_Document: "Құжат",
    l_Add_order: "Тапсырысты қосу",
    l_Can_choose_to_consolidation: "Біріктіруді таңдай алады",
    l_төленген_сома: "Төленген сома",
    l_Payment_method: "Төлем әдісі",
    l_Card: "карта",
    l_Cash: "Қолма-қол ақша",
    l_External_ID: "Сыртқы идентификатор",
    l_Verification_succeeded: "Тексеру сәтті өтті",
    l_Verification_failed: "Тексеру сәтсіз аяқталды",
    l_Car_number: "Автокөлік нөмірі",
    l_trailer_car_number: "Тіркеме вагонының нөмірі",
    l_Foreign_warehouse: "Шетел қоймасы",
    l_Almaty_warehouse: "Алматы қоймасы",
    l_Info: "Ақпарат",
    l_Payment_amount: "Төлем сомасы",
    l_Automatic_storage_filter_cleared_when_refresh: "Автоматты сақтау сүзгісі жаңарту кезінде тазаланады",
    l_not_found: "Табылмады",

    l_Contract_template: "Келісім-шарт үлгісі",
    l_File_format_error: "Файл пішімі дұрыс емес!",
    l_Back_home_page: "Өкінішке орай, сіз кірген бет табылмалы.",
    l_Picked_up: "алып кетті",
    l_Not_picked_up: "Алған жоқ",
    l_Payment_status: "Төлем күйі",
    l_Ready_to_ship: "Жіберуге дайын",
    l_Belongs_category: "Тәуелді түрі",
    l_File: "Файл",
    l_Poster: "Постер",
    l_format_error: "Форматы қате",
    l_TN_CODE: "TNVED коды",
    l_Other: "Басқа",
    l_Company: "Компания",
    l_Order_tracking: "Тапсырысты қадағалау",
    l_Not_fount: "Табылмады",
    l_Submit_and_print_label: "Жіберу және label шығару",
    l_Are_you_sure_update_password: "Құпия сөзді шынымен жаңартасыз ба?",
    l_Print: "Басып шығару",
    l_Operator: "Оператор",
    l_Russian_language: "Орыс тілінде",
    l_Print_label: "Label шығару",
    l_Warehouse: "Қойма",

    l_Checking: "Тексерілуде",
    l_Verification_failed_description: "Тексеруден өтпеу себебі",
    l_Select: "Таңдау",
    l_Gross_weight: "Брутто салмағы",
    l_Join_warehouse: "Қоймаға қосу",
    l_Batch_number: "Топтама нөмірі",
    l_Unclaimed_order: "Иесіз тапсырыс",
    l_China_batch: "CN batch",
    l_Reject_order: "Тапсырысты қабылдамау",
    l_Mini_batch: "Mini batch",
    l_Kazakhstan_batch: "KZ batch",
    l_Branch_warehouse: "Бөлімше",
    l_Driver: "Жүргізуші",
    l_Dest_station: "Межелі станция",
    l_Batch: "Batch",
    l_Shelf: "Сөре",
    l_Add_to_shelf: "Сөреге қосу",
    l_Data: "Деректер",
    l_Settings: "Параметрлер",
    l_Creator: "Құрушы",
    l_Shelf_number: "Сөре нөмірі",
    l_Sorting_center: "Сұрыптау орталығы",
    l_Pick_up: "Алып кету",
    l_Scan: "Сканерлеу",
    l_Last_six_digits_of_mobile_number: "Телефон нөміріңіздің соңғы алты саны",
    l_Branch_batch: "Branch batch",
    l_Arrived_to_branch: "Филиалға жетті",
    l_Arrived: "Жетті",
    l_App_version: "Қолданба нұсқасы",
    l_App_version_title: "Нұсқа атауы",
    l_App_version_content: "Нұсқа мазмұны",
    l_App_version_system: "Қолданба жүйесі",
    l_Version: "Нұсқа нөмірі",
    l_Force_update: "Мәжбүрлеп жаңарту керек пе?",
    l_Published: "Жарияланған",
    l_Unpublished: "Жарияланбаған",
    l_Export_excel: "Excel шығару",
    l_Work_time: "Жұмыс уақыты",
    l_Manager: "Басқарушы",
    l_Province: "Обылыс",
    l_Remark: "Ескертпе",
    l_Start: "Басталуы",
    l_End: "Аяқталуы",
    l_Do_not_add_randomly: "Кездейсоқ қосуға болмайды!",
    l_Find_branch: "Филиал анықтау",
    l_Refresh: "Жаңарту",
    l_Has_photo: "Суреті бар",
    l_Valid_time: "Жарамды уақыты",
    l_Visible_state: "Көріну күйі",
    l_File_update_time: "Файлды жаңарту уақыты",
    l_Time: "Уақыт",
    l_Created: "Құрылды",
    l_Group_payment: "Топтық төлем",
    l_Permission: "Рұқсат",
    l_System: "Жүйе",
    l_Info_api: "API ақпараты",
    l_Road_type: "Жол түрі",
    l_Official: "Ресми",
    l_Commerce: "Коммерция",
    l_Recommended_shelf: "Ұсынылған сөре",
    l_Amount_payable_by_the_client: "Клиент төлеуге тиісті сома",
    l_Payment_completed: "Төлем орындалды",
    l_Error_batch: "Қайтару batch",
    l_Error_order: "Қайтару тапсырыс",
    l_Error_mini_batch: "Қайтару mini batch",
    l_Work: "Жұмыс",

    l_China_logistics: "Қытай логистикасы",
    l_Receiver: "Қабылдаушы",
    l_Transport_day: "Тасымалдау күні",
    l_Day: "Күн",
    l_tel_merge: "Телефонды біріктіру",
    l_Abnormal_order: "Мәселелі тапсырыс",
    l_Customs: "Кеден",
    l_Contract: "Келісімшарт",
    l_Invoice: "Invoice",
    l_Filter: "Фильтр",
    l_CN_category_id: "CN категориясының идентификаторы",
    l_Prohibited_catgegories: "Тыйым салынған санат",
    l_Error: "Техникалық қателік орын алды! IT бөліміне скрин жіберіңіз.",
    l_Those_whose_weight_is_over_31_kg: "Салмағы 31 кг-нан асқандар",
    l_The_price_is_more_than_1000: "Бағасы 1000$ асқандар",
    l_Price_over_1000_and_weight_over_31_kg: "Бағасы 1000$ асқандар және салмағы 31 кгнан асқандар",
    l_Net_weight: "Таза салмақ",
    l_Overall_weight: "Жалпы салмақ",
    l_History: "Тарихы",

    l_Updated_time: "Өзгертілген уақыт",
    l_Order_count: "Тапсырыс саны",
    l_Unknown: "Белгісіз",
    l_Mini_batch_count: "Mini batch саны",
    l_Return_order: "қайтарылған тапсырыс",

    l_Change_branch: "Бөлімше өзгерту",
    l_Image: "Сурет",
    l_Search_in_shelf: "Сөреден іздеу",
    l_Translation: "Аударма",
    l_Primary_language: "Бастапқы тіл",
    l_Content: "Мазмұны",
    l_Translated_language: "Aударылған тіл",
    l_Check_the_category_of_KTZ: "KTZ категориясын тексеру",
    l_There_is_KTZ_category: "KTZ категориясы бар",
    l_There_is_no_category_KTZ: "KTZ категориясы жок",
    l_Statistic: "Статистика",
    l_Are_you_sure_of_the_correctness_of_the_data: "Деректердің дұрыстығына сенімдісіз бе?",
    l_China_warehouse: "Қытай қоймасы",
    l_Sorting: "Сұрыптау",
    l_Role: "Рөл",
    l_Stay_branch_day: "Бөлімшеде тұрған күн (мин)",
    l_Min_weight: "Салмақ (мин)",
    l_Menu: "Меню",
    l_Left_from_Almaty: "Алматыдан шықты",
    l_Left_from_China: "Қытайдан шықты",
    l_Almaty_warehouse_received: "Алматы қоймасы қабылдап алды",
    l_China_warehouse_received: "Қытай қоймасы қабылдап алды",
    l_Jpid_build_by_minibatch: "Minibatch-ка салған jpid",
    l_Weighed_id: "Салмағын өлшеген id",
    l_Close: "Жабу",
    l_Article: "Мақала",
    l_Employees: "Қызметкерлер",

    l_Visibility: "Көріну",
    l_Can_view: "Көруге болады",
    l_Admin_worker: "Админ қызметкері",
    l_Web: "Веб",
    l_General: "Жалпы",
    l_Door_to_door: "Есіктен есікке",
    l_Pending_orders: "Күтудегі тапсырыстар",
    l_In_delivery: "Жеткізілуде",
    l_Received: "Алынды",
    l_Reject: "Қабылдамау",
    l_Pending: "Күтуде",
    l_Supervision: "Қадағалау",
    l_Language: "Тілі",
    l_Agent: "Агент",
    l_My_Statistics: "Менің статистикам",
    l_Count_Stats: "Санақ статистикасы",
    l_Recharge_the_balance: "Баланс толтыру",
    l_Transactions: "Аударымдар",
    l_Transaction_type: " Аударым түрі",
    l_Old_password: "Ескі құпия сөз",
    l_Reset: "Қалпына келтіру",
    l_Transactions_detail: "Транзакция мәліметтері",
    l_Clear: "Өшіру",
    l_Created_time: "Жасалған уақыт",
    l_Source_type: "Төлем түрі",
    l_Comment: "Комментарий",
    l_Admin_add: "Админ қосу",
    l_Username: "Қолданушы",
    l_Datails: "Толық мәлімет",
    l_Destination_JPID: "Қабылдаушы JPID",
    l_Source_ID: "Жіберуші ID",
    l_Clear_account: "Баланс тазалау",
    l_See_more: "Толығырақ көру",
    l_List: "Тізім",
    l_Code: "Kод",
    l_Payment_time: "Төлем уақыты",
    l_Payment_list: "Төлем тізімі",
    l_OrderPay: "Тапсырысты төлеу",
    l_AddFunds: "Шотты толықтыру",
    l_Account2account_translate: "Шоттар арасындағы аударым",
    l_Account2account_add_bonus: "Бонус қосу",
    l_Refund: "Шотқа ақша қайтарылды",
    l_Single_plus: "Ақша қосу",
    l_Single_minus: "Ақша алу",
    l_Single_clear: "Шот тазартылды",
    l_Business_type: "Бизнес типі",
    l_Exchange_rate: "Айырбастау курсы",
    l_Currency: "Валюта",
    l_Source: "Қайдан",
    l_Destination: "Қайда",
    l_Translate: "Аударым",
    l_Limit_type: "Шектеу типі",
    l_Method_type: "Әдіс типі",
    l_Percent: "Пайыз",
    l_No_limit: "Шектеусіз",
    l_Time_limit: "Шектеулі",
    l_Save: "Сақтау",
    l_Actions: "Жеңілдіктер",
    l_Recompile: "Қайта жүргізу",
    l_Account2account_course_pay: "Курс төлемі",
    l_Sum: "Сумма",

    l_kaspi: "Kaspi",
    l_card: "Card",
    l_epay: "Epay",
    l_kazpost: "KazPost",
    l_pay_me: "Payme",
    l_freedom_pay: "Freedom Pay",
    l_tarlan: "Tarlan",
    l_click: "Click",
    l_uzum: "Uzum",
    l_mbank: "MBank",
    l_uzum_app: "Uzum App",
}
