export default {
    l_User_name: "User name",
    l_User_password: "User password",
    l_Forgot_password: "Forgot password",
    l_Login: "Log in",
    l_Sign_out: "Sign out",
    l_EmailorPasswordisWrong: "Email or passwordis wrong",
    l_User: "User",
    l_Payments: "Payments",
    l_Promocodes: "Promocodes",
    l_Messages: "Messages",
    l_Consolidations: "Consolidations",
    l_Courier_Orders: "Courier Orders",
    l_Almaty: "Almaty",
    l_Name: "Name",
    l_Add: "Add",
    l_Action: "Action",
    l_Update: "Update",
    l_Edit: "Edit",
    l_Submit: "Submit",
    l_Cancel: "Cancel",
    l_Are_you_sure_delete_this_task: "Are you sure delete this task?",
    l_Yes: "Yes",
    l_No: "No",
    l_Tracking_number: "Tracking number",
    l_Internal_Tracking_number: "Internal Tracking number",
    l_Actual_Price: "Actual Price",
    l_Estimated_price: "Estimated price",
    l_Actual_weight: "Actual weight",
    l_kg: "kg",
    l_Estimated_weight: "Estimated weight",
    l_Delivery_country: "Delivery country",
    l_Delivery_type: "Delivery type",
    l_Address: "Address",
    l_Execution_status: "Execution status",
    l_Cargo: "Cargo",
    l_Sign_up: "Sign up",
    l_Next: "Next",
    l_Verify_code: "Verify code",
    l_Set_password: "Set password",
    l_Confirm_password: "Confirm password",
    l_Get_SMS: "Get SMS",
    l_Get_the_verification_code_first: "Get the verification code first",
    l_Confirm_password_error: "Confirm password error",
    l_Phone_number: "Phone number",
    l_Password: "Password",
    l_Wrong_password_format: "Wrong password format",
    l_Please_enter_phone_number: "Please enter phone number",
    l_Please_enter_sms: "Please enter SMS",
    l_My_addresses: "My addresses",
    l_My_parcels: "My parcels",
    l_Buy_instead_of_me: "Buy instead of me",
    l_Personal_data: "Personal data",
    l_Update_password: "Update password",
    l_Old_Password: "Old password",
    l_New_Password: "New password",
    l_Successed: "Successed",
    l_Personal_information: "Personal information",
    l_User_list: "User list",
    l_Email: "Email",
    l_Add_user: "Add payment",
    l_Full_name: "Full name",
    l_IIN: "IIN",
    l_ID_number: "ID number",
    l_Issuing_authority: "Issuing authority",
    l_Issue_date: "Issue date",
    l_Validity: "Validity",
    l_Delivery_address: "Delivery address",
    l_Town: "City/Town",
    l_Street: "Street",
    l_House: "House",
    l_Postcode: "Postcode",
    l_Identity_card: "Identity card",
    l_Facial: "Facial",
    l_Negotiable: "Negotiable",
    l_Draw_a_signature: "Draw a signature",
    l_Or_download_the_signed_contract: "Or download the signed contract",
    l_Note: "Note",
    l_Precautions_for_uploading_pictures: "The attached document must be of good quality. The image file must not exceed 1 MB.",
    l_Click_or_drag_ile_to_this_area_to_upload: "Click or drag file to this area to upload",
    l_My_order: "My order",
    l_Track_code: "Track code",
    l_Recipient: "Recipient",
    l_Cargo_name: "Cargo name",
    l_Declared_price: "Declared price",
    l_Date: "Date",
    l_Delivery_cost: "Delivery cost",
    l_Dispatch_country: "Dispatch country",
    l_Delivery_method: "Delivery method",
    l_Order: "Order",
    l_Recipient_data: "Recipient data",
    l_Shipping_country: "Shipping country",
    l_Transportation_method: "Transportation method",
    l_Price: "Price",
    l_Quantity: "Quantity",
    l_Total_cost: "Total cost",
    l_Category: "Category",
    l_Weapons_and_explosive_materials: "Weapons and explosive materials",
    l_Alcoholic_drinks: "Alcoholic drinks",
    l_Sprays_and_aerosols: "Sprays and aerosols",
    l_Medicines_without_a_doctors_prescription: "Medicines without a doctor's prescription",
    l_radioactive_elements: "radioactive elements",
    l_Animals_and_plants: "Animals and plants",
    l_ALL_RIGHTS_RESERVED: "ALL RIGHTS RESERVED",
    l_Contact: "Contact",
    l_About_us: "About us",
    l_The_shops: "The shops",
    l_How_it_works: "How it works?",
    l_Rates: "Rates",
    l_Frequently_asked_Questions: "Frequently asked Questions",
    l_Prohibited_Items: "Prohibited Items",
    l_Privacy_Policy: "Privacy Policy",
    l_Weight: "Weight",
    l_Declaration: "Declaration",
    l_Pay: "Pay",
    l_Provinces: "Provinces",
    l_Country: "Country",
    l_Full_address: "Full address",
    l_MY_ADDRESS_IN_CHINA_FOR_AUTO_DELIVERY: "MY ADDRESS IN CHINA FOR AUTO DELIVERY",
    l_Add_product: "Add cargo",
    l_Please_enter_quantity: "Please enter quantity",
    l_The_maximum_number_is: "The maximum number is",
    l_China: "China",
    l_Auto_delivery: "Auto delivery",
    l_To_avoid_problems_during_customs_clearance_please_enter_a_detailed_description_of_the_product_name_in_Russian: "To avoid problems during customs clearance, please enter a detailed description of the product name in Russian.",
    l_Later_confirm: "Later Confirm",
    l_Confirm: "Confirm",
    l_Succeed: "Succeed",
    l_Provide_a_link_to_the_product_we_will_buy_and_bring_it_for_you: "Provide a link to the product, we will buy and bring it for you!",
    l_Cargo_link: "Cargo link",
    l_Additional_comment: "Additional comment",
    l_Are_you_willing_to_buy_if_the_price_fluctuates_up_to_7: "Are you willing to buy if the price fluctuates up to 7% ?",
    l_size: "Size",
    l_Color: "Color",
    l_Delivery_price: "Delivery price",
    l_Promo_codes: "Promo codes",
    l_Whether_the_payment_is_completed: "Whether the payment is completed?",
    l_Completed: "Completed",
    l_Not_completed: "Not completed",
    l_Image_must_smaller_than_3MB: "Image must smaller than 3MB!",
    l_Image_must_smaller_than_2MB: "Image must smaller than 2MB!",
    l_Image_format_error: "image format error!",

    l_Analytics: "Analytics",
    l_Status: "Status",
    l_Order_id: "Order id",
    l_Traking_number: "Traking number",
    l_Amount: "Amount",
    l_Payment_metehod: "Payment metehod",
    l_Payment: "Payment",
    l_Recipients: "Recipients",
    l_First_name: "First name",
    l_Last_name: "Last name",
    l_Type: "Type",
    l_Client: "Client",
    l_Admin: "Admin",
    l_Last_login: "Last login",
    l_Register: "Register",
    l_Verified: "Verified",
    l_Not_verified: "Verification failed",
    l_All: "All",
    l_Search: "Search",
    l_Statuses: "Statuses",
    l_Start_country: "Start country",
    l_End_country: "End country",
    l_Sort: "Sort",
    l_Add_time: "Add time",
    l_Update_time: "Update time",
    l_Ascending: "Ascending",
    l_Descending: "Descending",
    l_Create_time: "Create time",
    l_Message: "Message",
    l_Send_message: "Send message",
    l_Internal_tracking_number: "Internal tracking number",
    l_Shopping_instead: "Shopping instead",
    l_Size: "Size",
    l_Description: "Description",
    l_Link: "Link",
    l_City: "City",
    l_All_cargo: "All cargo",
    l_Volume: "Volume",
    l_Height: "Height",
    l_Paid: "Paid",
    l_Unpaid: "Unpaid",
    l_Declaration_verify: "Declaration verify",
    l_Update_date: "Update date",
    l_Add_date: "Add date",
    l_Add_to_consolidation: "Add to consolidation",
    l_Consolidation: "Consolidation",
    l_Consolidation_id: "Consolidation ID",
    l_Add_type: "Add type",
    l_Add_new: "Add new",
    l_Contractor: "Contractor",
    l_Selected_order_quantity: "Selected order quantity",
    l_Flight_number: "Flight number",
    l_Execution_date: "Execution date",
    l_Document: "Document",
    l_Add_order: "Add order",
    l_Can_choose_to_consolidation: "Can choose to consolidation",
    l_Amount_paid: "Amount paid",
    l_Payment_method: "Payment method",
    l_Card: "Card",
    l_Cash: "Cash",
    l_External_ID: "External ID",
    l_Verification_succeeded: "Verification succeeded",
    l_Verification_failed: "Verification failed",
    l_Car_number: "Car number",
    l_trailer_car_number: "Trailer car number",
    l_Foreign_warehouse: "Foreign warehouse",
    l_Almaty_warehouse: "Almaty warehouse",
    l_Info: "Info",
    l_Payment_amount: "Payment amount",
    l_Automatic_storage_filter_cleared_when_refresh: "Automatic storage filter, cleared when refresh",
    l_Not_found: "Not found",

    l_Contract_template: "Contract template",
    l_File_format_error: "File format error!",
    l_Back_home_page: "Back Home",
    l_Picked_up: "Picked up",
    l_Not_picked_up: "Not picked up",
    l_Payment_status: "Payment status",
    l_Ready_to_ship: "Ready_to_ship",
    l_Belongs_category: "Belongs category",
    l_File: "File",
    l_Poster: "Poster",
    l_format_error: "Format error",
    l_TN_CODE: "TNVED code",
    l_Other: "Other",
    l_Company: "Company",
    l_Order_tracking: "Order tracking",
    l_Not_fount: "Not fount",
    l_Submit_and_print_label: "Submit and print label",
    l_Are_you_sure_update_password: "Are you sure update password?",
    l_Print_: "Print",
    l_Operator: "Operator",
    l_Russian_language: "Russian language",
    l_Print_label: "Print label",
    l_Warehouse: "Warehouse",

    l_Checking: "Under review",
    l_Verification_failed_description: "Failure reason",
    l_App_version: "App version",
    l_App_version_title: "Version title",
    l_App_version_content: "Version content",
    l_App_version_system: "App system",
    l_Version: "Version number",
    l_Force_update: "Need to force update?",
    l_Published: "Published",
    l_Unpublished: "Unpublished",
    l_Select: "Select",
    l_Gross_weight: "Gross weight",
    l_Join_warehouse: "Join warehouse",
    l_Batch_number: "Batch number",
    l_Unclaimed_order: "Unclaimed order",
    l_China_batch: "CN batch",
    l_Reject_order: "Reject order",
    l_Mini_batch: "Mini batch",
    l_Kazakhstan_batch: "KZ batch",
    l_Branch_warehouse: "Branch",
    l_Driver: "Driver",
    l_Dest_station: "Dest station",
    l_Batch: "Batch",
    l_Shelf: "Shelf",
    l_Add_to_shelf: "Add to shelf",
    l_Data: "Data",
    l_Settings: "Settings",
    l_Creator: "Creator",
    l_Shelf_number: "Shelf number",
    l_Sorting_center: "Sorting center",
    l_Pick_up: "Pick up",
    l_Scan: "Scan",
    l_Last_six_digits_of_mobile_number: "Last six digits of mobile number",
    l_Branch_batch: "Branch batch",
    l_Arrived_to_branch: "Arrived to branch",
    l_Arrived: "Arrived",
    l_Export_excel: "Export Excel",
    l_Work_time: "Work time",
    l_Manager: "Manager",
    l_Province: "Province",
    l_Remark: "Remark",
    l_Start: "Start",
    l_End: "End",
    l_Do_not_add_randomly: "Do not add randomly!",
    l_Find_branch: "Find branch",
    l_Refresh: "Refresh",
    l_Has_photo: "Has photo",
    l_Valid_time: "Valid time",
    l_Info_api: "Info API",
    l_Visible_state: "Visible state",
    l_File_update_time: "File update time",
    l_Time: "Time",
    l_Created: "Created",
    l_Group_payment: "Group payment",
    l_Permission: "Permission",
    l_System: "System",
    l_Tag: "Tag",
    l_Road_type: "Road type",
    l_Official: "Official",
    l_Commerce: "Commerce",
    l_Recommended_shelf: "Recommended shelf",
    l_Amount_payable_by_the_client: "Amount payable by the client",
    l_Payment_completed: "Payment completed",
    l_Error_batch: "Error batch",
    l_Error_order: "Error order",
    l_Error_mini_batch: "Error mini batch",
    l_Work: "Work",
    l_China_logistics: "China logistics",
    l_Receiver: "Receiver",
    l_Transport_day: "Transport day",
    l_Day: "Day",
    l_tel_merge: "Phone merge",
    l_Abnormal_order: "Abnormal order",
    l_Customs: "Customs",
    l_Contract: "Contract",
    l_Invoice: "Invoice",
    l_Filter: "Filter",
    l_CN_category_id: "CN category id",
    l_Prohibited_catgegories: "Prohibited categories",
    l_Error: "A technical error has occurred! Please send a screenshot to IT.",
    l_Those_whose_weight_is_over_31_kg: "Those whose weight is over 31 kg",
    l_The_price_is_more_than_1000: "The price is more than 1000$",
    l_Price_over_1000_and_weight_over_31_kg: "Price over 1000$ and weight over 31kg",
    l_Net_weight: "Net weight",
    l_Overall_weight: "Overall weight",
    l_History: "History",

    l_Updated_time: "Updated time",
    l_Order_count: "Order count",
    l_Unknown: "Unknown",
    l_Mini_batch_count: "Mini batch count",
    l_Return_order: "Return order",
    l_Change_branch: "Change branch",
    l_Image: "Image",
    l_Search_in_shelf: "Search in shelf",
    l_Translation: "Translation",
    l_Primary_language: "Primary language",
    l_Content: "Content",
    l_Translated_language: "Translated language",
    l_Check_the_category_of_KTZ: "Check the category of KTZ",
    l_There_is_KTZ_category: "There is a KTZ category",
    l_There_is_no_category_KTZ: "There is no category KTZ",
    l_Are_you_sure_of_the_correctness_of_the_data: "Are you sure of the correctness of the data?",
    l_Statistic: "Statistic",
    l_China_warehouse: "China warehouse",
    l_Sorting: "Sorting",
    l_Role: "Role",
    l_Stay_branch_day: "Stay branch day",
    l_Min_weight: "Min weight",
    l_Menu: "Menu",
    l_Left_from_Almaty: "Left from Almaty",
    l_Left_from_China: "Left from China",
    l_Almaty_warehouse_received: "Almaty warehouse received",
    l_China_warehouse_received: "China warehouse received",
    l_Jpid_build_by_minibatch: "Jpid built by minibatch",
    l_Weighed_id: "Weighed id",
    l_Close: "Close",
    l_Article: "Article",
    l_Employees: "Employees",
    l_Visibility: "Visibility",
    l_Can_view: "Can view",
    l_Admin_worker: "Admin worker",
    l_Web: "Web",
    l_General: "General",
    l_Door_to_door: "Door to door",
    l_Pending_orders: "Pending orders",
    l_In_delivery: "In delivery",
    l_Received: "Received",
    l_Reject: "Reject",
    l_Pending: "Pending",
    l_Supervision: "Supervision",
    l_Language: "Language",
    l_Agent: "Agent",
    l_My_Statistics: "My Statistics",
    l_Count_Stats: "Count  Statistics",
    l_Recharge_the_balance: "Recharge the balance",
    l_Transactions: "Transactions",
    l_Transaction_type: "Transaction Type",
    l_Old_password: "Old password",
    l_Reset: "Reset",
    l_Transactions_detail: "Transaction detail",
    l_Clear: "Clear",
    l_Created_time: "Created time",
    l_Source_type: "Method of payment",
    l_Comment: "Comment",
    l_Admin_add: "Add Admin",
    l_Username: "Username",
    l_Datails: "Details",
    l_Destination_JPID: "Destination JPID",
    l_Source_ID: "Sourse ID",
    l_Clear_account: "Clear account",
    l_See_more: "See more",
    l_List: "List",
    l_Code: "Code",
    l_Payment_time: "Payment Time",
    l_Payment_list: "Payment List",
    l_OrderPay: "Order payment",
    l_AddFunds: "Top up your balance",
    l_Account2account_translate: "Transfer between accounts",
    l_Account2account_add_bonus: "Add Bonus",
    l_Refund: "Money returned to account",
    l_Single_plus: "Single plus",
    l_Single_minus: "Single minus",
    l_Single_clear: "Wallet cleared",
    l_Business_type: "Business type",
    l_Exchange_rate: "Exchange Rate",
    l_Currency: "Currency",
    l_Source: "Source",
    l_Destination: "Destination",
    l_Translate: "Translate",
    l_Limit_type: "Limit type",
    l_Method_type: "Method type",
    l_Percent: "Percent",
    l_No_limit: "Unlimit",
    l_Time_limit: "Limited",
    l_Save: "Save",
    l_Actions: "Action",
    "l_Recompile": "Recompile",
    "l_Account2account_course_pay": "Course pay",
    "l_Sum": "Sum",

    l_kaspi: "Kaspi",
    l_card: "Card",
    l_epay: "Epay",
    l_kazpost: "KazPost",
    l_pay_me: "Payme",
    l_freedom_pay: 'Freedom Pay',
    l_tarlan: "Tarlan",
    l_click: "Click",
    l_uzum: "Uzum",
    l_mbank: "MBank",
    l_uzum_app: "Uzum App"
}
