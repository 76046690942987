export default {
    l_User_name: "Имя пользователя",
    l_User_password: "Пароль",
    l_Forgot_password: "Восстановить пароль",
    l_Login: "Войти",
    l_Sign_out: "Выход",
    l_EmailorPasswordisWrong: "Электронная почта или пароль неверны",
    l_User: "Пользователь",
    l_Payments: "Платежи",
    l_Promocodes: "Промокоды",
    l_Messages: "Сообщения",
    l_Consolidations: "Консолидации",
    l_Courier_Orders: "Курьерские заказы",
    l_Almaty: "Алматы",
    l_Name: "Название",
    l_Add: "Добавить",
    l_Action: "Действие",
    l_Update: "Обновить",
    l_Edit: "Редактировать",
    l_Submit: "Отправить",
    l_Cancel: "Отмена",
    l_Are_you_sure_delete_this_task: "Вы уверены, что хотите удалить эту задачу?",
    l_Yes: "Да",
    l_No: "Нет",
    l_Tracking_number: "Номер отслеживания",
    l_Internal_Tracking_number: "Внутренний номер отслеживания",
    l_Actual_Price: "Фактическая цена",
    l_Estimated_price: "Расчетная цена",
    l_Actual_weight: "Фактический вес",
    l_kg: "кг",
    l_Estimated_weight: "Расчетный вес",
    l_Delivery_country: "Страна доставки",
    l_Delivery_type: "Тип доставки",
    l_Address: "Адрес",
    l_Execution_status: "Статус выполнения",
    l_Cargo: "Груз",
    l_Sign_up: "Зарегистрироваться",
    l_Next: "Далее",
    l_Verify_code: "Проверить код",
    l_Set_password: "Установить пароль",
    l_Confirm_password: "Подтвердите пароль",
    l_Get_SMS: "Получить SMS",
    l_Get_the_verification_code_first: "Сначала получите код подтверждения",
    l_Confirm_password_error: "Ошибка подтверждения пароля",
    l_Phone_number: "Номер телефона",
    l_Password: "Пароль",
    l_Wrong_password_format: "Неверный формат пароля",
    l_Please_enter_phone_number: "Пожалуйста, введите номер телефона",
    l_Please_enter_sms: "Пожалуйста, введите SMS",
    l_My_addresses: "Мои адреса",
    l_My_parcels: "Мои посылки",
    l_Buy_instead_of_me: "Купить вместо меня",
    l_Personal_data: "Личные данные",
    l_Update_password: "Обновить пароль",
    l_Old_Password: "Старый пароль",
    l_New_Password: "Новый пароль",
    l_Successed: "Успешно",
    l_Personal_information: "Личные данные",
    l_User_list: "Список пользователей",
    l_Email: "Электронная почта",
    l_Add_user: "Добавить пользователя",
    l_Full_name: "ФИО",
    l_IIN: "ИИН",
    l_ID_number: "Номер уд.личности",
    l_Issuing_authority: "Орган выдачи",
    l_Issue_date: "Дата выдачи",
    l_Validity: "Срок действия",
    l_Delivery_address: "Адрес доставки",
    l_Town: "Город/Район",
    l_Street: "Улица",
    l_House: "Дом",
    l_Postcode: "Почтовый индекс",
    l_Identity_card: "Удостоверение личности",
    l_Facial: "Лицевая",
    l_Negotiable: "Оборотная",
    l_Draw_a_signature: "Нарисуйте подпись",
    l_Or_download_the_signed_contract: "Или загрузите подписанный договор",
    l_Note: "Примечание",
    l_Precautions_for_uploading_pictures: "Прикрепляемый документ должен быть хорошего качества. Файл изображения не должен превышать 1 МБ.",
    l_Click_or_drag_ile_to_this_area_to_upload: "Нажмите или перетащите файл в эту область, чтобы загрузить",
    l_My_order: "Мой заказ",
    l_Track_code: "Трек-код",
    l_Recipient: "Получатель",
    l_Cargo_name: "Название груза",
    l_Declared_price: "Заявленная стоимость",
    l_Date: "Дата",
    l_Delivery_cost: "Стоимость доставки",
    l_Dispatch_country: "Страна отправки",
    l_Delivery_method: "Способ доставки",
    l_Order: "Заказ",
    l_Recipient_data: "Данные получателя",
    l_Shipping_country: "Страна доставки",
    l_Transportation_method: "Способ транспортировки",
    l_Price: "Цена",
    l_Quantity: "Количество",
    l_Total_cost: "Общая стоимость",
    l_Category: "Категория",
    l_Weapons_and_explosive_materials: "Оружие и взрывоопасные материалы",
    l_Alcoholic_drinks: "Алкогольные напитки",
    l_Sprays_and_aerosols: "Спреи и аэрозоли",
    l_Medicines_without_a_doctors_prescription: "Медикаменты без рецепта врача",
    l_radioactive_elements: "Радиоактивные элементы",
    l_Animals_and_plants: "Животные и растения",
    l_ALL_RIGHTS_RESERVED: "ВСЕ ПРАВА ЗАЩИЩЕНЫ",
    l_Contact: "Контакт",
    l_About_us: "О нас",
    l_The_shops: "Магазины",
    l_How_it_works: "Как это работает?",
    l_Rates: "Тарифы",
    l_Frequently_asked_Questions: "Часто задаваемые вопросы",
    l_Prohibited_Items: "Запрещенные предметы",
    l_Privacy_Policy: "Политика конфиденциальности",
    l_Weight: "Вес",
    l_Declaration: "Декларация",
    l_Pay: "Оплатить",
    l_Provinces: "Области",
    l_Country: "Страна",
    l_Full_address: "Полный адрес",
    l_MY_ADDRESS_IN_CHINA_FOR_AUTO_DELIVERY: "МОЙ АДРЕС В КИТАЕ ДЛЯ АВТО ДОСТАВКИ",
    l_Add_product: "Добавить груз",
    l_Please_enter_quantity: "Пожалуйста, введите количество",
    l_The_maximum_number_is: "The maximum number is",
    l_China: "Китай",
    l_Auto_delivery: "Авто доставка",
    l_To_avoid_problems_during_customs_clearance_please_enter_a_detailed_description_of_the_product_name_in_Russian: "Во избежание проблем при таможенном оформлении просьба указывать подробное описание наименования товара на русском языке.",
    l_Later_confirm: "Позже подтвердить",
    l_Confirm: "Подтвердить",
    l_Succeed: "Операция прошла успешно",
    l_Provide_a_link_to_the_product_we_will_buy_and_bring_it_for_you: "Отправьте ссылку на товар, мы купим и привезем его для вас!",
    l_Cargo_link: "Ссылка на груз",
    l_Additional_comment: "Дополнительный комментарий",
    l_Are_you_willing_to_buy_if_the_price_fluctuates_up_to_7: "Согласны ли вы на покупку, если цена колеблется до 7% ?",
    l_size: "Размер",
    l_Color: "Цвет",
    l_Delivery_price: "Цена за доставку",
    l_Promo_codes: "Промокоды",
    l_Whether_the_payment_is_completed: "Платеж завершен?",
    l_Completed: "Завершено",
    l_Not_completed: "Не завершено",
    l_Image_must_smaller_than_3MB: "Изображение должно быть меньше 3 МБ!",
    l_Image_must_smaller_than_2MB: "Image must smaller than 2MB!",
    l_Image_format_error: "Ошибка формата изображения!",

    l_Analytics: "Аналитика",
    l_Status: "Статус",
    l_Order_id: "Идентификатор заказа",
    l_Traking_number: "Номер отслеживания",
    l_Amount: "Сумма",
    l_Payment_metehod: "Способ оплаты",
    l_Payment: "Платеж",
    l_Recipients: "Получатели",
    l_First_name: "Имя",
    l_Last_name: "Фамилия",
    l_Type: "Тип",
    l_Client: "Клиент",
    l_Admin: "Админ",
    l_Last_login: "Последний вход",
    l_Register: "Зарегистрироваться",
    l_Verified: "Проверено",
    l_Not_verified: "Проверка не удалась",
    l_All: "Все",
    l_Search: "Поиск",
    l_Statuses: "Статусы",
    l_Start_country: "Начальная страна",
    l_End_country: "Конечная страна",
    l_Sort: "Сортировать",
    l_Add_time: "Добавить время",
    l_Update_time: "Время изменения",
    l_Ascending: "По возрастанию",
    l_Descending: "По убыванию",
    l_Create_time: "Время создания",
    l_Message: "Сообщение",
    l_Send_message: "Отправить сообщение",
    l_Internal_tracking_number: "Внутренний номер отслеживания",
    l_Shopping_instead: "Вместо этого покупки",
    l_Size: "Размер",
    l_Description: "Описание",
    l_Link: "Ссылка",
    l_City: "Город",
    l_All_cargo: "Все грузы",
    l_Volume: "Объем",
    l_Height: "Высота",
    l_Paid: "Оплачено",
    l_Unpaid: "Не оплачено",
    l_Declaration_verify: "Подтверждение декларации",
    l_Update_date: "Дата обновления",
    l_Add_date: "Добавить дату",
    l_Add_to_consolidation: "Добавить в консолидацию",
    l_Consolidation: "Консолидация",
    l_Consolidation_id: "Идентификатор консолидации",
    l_Add_type: "Добавить тип",
    l_Add_new: "Добавить новый",
    l_Contractor: "Подрядчик",
    l_Selected_order_quantity: "Выбранное количество заказа",
    l_Flight_number: "Номер рейса",
    l_Execution_date: "Дата выполнения",
    l_Document: "Документ",
    l_Add_order: "Добавить заказ",
    l_Can_choose_to_consolidation: "Может выбрать консолидацию",
    l_Amount_paid: "Выплаченная сумма",
    l_Payment_method: "Способ оплаты",
    l_Card: "Карта",
    l_Cash: "Наличные",
    l_External_ID: "Внешний идентификатор",
    l_Verification_succeeded: "Проверка прошла успешно",
    l_Verification_failed: "Проверка не удалась",
    l_Car_number: "Номер автомобиля",
    l_trailer_car_number: "Номер прицепа",
    l_Foreign_warehouse: "Зарубежный склад",
    l_Almaty_warehouse: "Алматинский склад",
    l_Info: "Информация",
    l_Payment_amount: "Сумма платежа",
    l_Automatic_storage_filter_cleared_when_refresh: "Автоматический фильтр хранения, очищается при обновлении",
    l_Not_found: "Не найдено",

    l_Contract_template: "Шаблон договора",
    l_File_format_error: "Ошибка формата файла!",
    l_Back_home_page: "Вернуться на главную страницу",
    l_Picked_up: "Поднят",
    l_Not_picked_up: "Не поднято",
    l_Payment_status: "Статус платежа",
    l_Ready_to_ship: "Готово к отправке",
    l_Belongs_category: "Принадлежит категории",
    l_File: "Файл",
    l_Poster: "Постер",
    l_format_error: "Ошибка формата",
    l_TN_CODE: "TNVED код ",
    l_Other: "Другое",
    l_Company: "Компания",
    l_Order_tracking: "Найти посылку",
    l_Not_fount: "Не найдено",
    l_Submit_and_print_label: "Отправить и label этикетку",
    l_Are_you_sure_update_password: "Вы уверены, что обновили пароль?",
    l_Print: "Печать",
    l_Operator: "Оператор",
    l_Russian_language: "Русский язык",
    l_Print_label: "Печать label",
    l_Warehouse: "Склад",

    l_Checking: "На рассмотрении",
    l_Verification_failed_description: "Причина неудачи проверки",
    l_App_version: "Версия приложения",
    l_App_version_title: "Название версии",
    l_App_version_content: "Содержимое версии",
    l_App_version_system: "Система приложений",
    l_Version: "Номер версии",
    l_Force_update: "Нужно принудительно обновить?",
    l_Published: "Опубликовано",
    l_Unpublished: "Не опубликовано",
    l_Select: "Выбрать",
    l_Gross_weight: "Вес брутто",
    l_Join_warehouse: "Подключить склад",
    l_Batch_number: "Номер партии",
    l_Unclaimed_order: "Неполный заказ",
    l_China_batch: "CN batch",
    l_Reject_order: "Отклонить заказ",
    l_Mini_batch: "Мини batch",
    l_Kazakhstan_batch: "KZ batch",
    l_Branch_warehouse: "Филиал",
    l_Driver: "Драйвер",
    l_Dest_station: "Конечная станция",
    l_Batch: "Batch",
    l_Shelf: "Полка",
    l_Add_to_shelf: "Добавить на полку",
    l_Data: "Данные",
    l_Settings: "Настройки",
    l_Creator: "Создатель",
    l_Shelf_number: "Номер полки",
    l_Sorting_center: "Сортировочный центр",
    l_Pick_up: "Забрать",
    l_Scan: "Сканировать",
    l_Last_six_digits_of_mobile_number: "Последние шесть цифр номера мобильного телефона",
    l_Branch_batch: "Branch batch",
    l_Arrived_to_branch: "Прибыл в филиал",
    l_Arrived: "Прибыл",
    l_Export_excel: "Экспорт Excel",
    l_Work_time: "Рабочее время",
    l_Manager: "Менеджер",
    l_Province: "Провинция",
    l_Remark: "Примечание",
    l_Start: "Начало",
    l_End: "Конец",
    l_Do_not_add_randomly: "Не добавлять случайным образом!",
    l_Find_branch: "Проверить филиал",
    l_Refresh: "Обновить",
    l_Has_photo: "Есть фото",
    l_Valid_time: "Жарамды уақыты",
    l_Visible_state: "Видимое состояние",
    l_File_update_time: "Время обновления файла",
    l_Time: "Время",
    l_Created: "Создано",
    l_Group_payment: "Групповая оплата",
    l_Permission: "Разрешение",
    l_System: "Система",
    l_Info_api: "Инфо об API",
    l_Road_type: "Тип дороги",
    l_Official: "Официальный",
    l_Commerce: "Коммерция",
    l_Recommended_shelf: "Рекомендуемая полка",
    l_Amount_payable_by_the_client: "Сумма к оплате клиентом",
    l_Payment_completed: "Платеж выполнен",
    l_Error_batch: "Проблема с batch",
    l_Error_order: "Проблема с заказ",
    l_Error_mini_batch: "Проблема mini batch",
    l_Work: "Работа",

    l_China_logistics: "Логистика Китая",
    l_Receiver: "Получатель",
    l_Transport_day: "Дни на доставку",
    l_Day: "День",
    l_tel_merge: "Слияние телефонов",
    l_Abnormal_order: "Неправильный заказ",
    l_Customs: "Таможня",
    l_Contract: "Контракт",
    l_Invoice: "Invoice",
    l_Filter: "Фильтр",
    l_CN_category_id: "Идентификатор категории Китая",
    l_Prohibited_catgegories: "Запрещенные категории",
    l_Error: "Произошла техническая ошибка! Пожалуйста, пришлите скриншот в IT-отдел.",
    l_Those_whose_weight_is_over_31_kg: "Вес более 31 кг",
    l_The_price_is_more_than_1000: "Цена более 1000$",
    l_Price_over_1000_and_weight_over_31_kg: "Цена превышает 1000$ и весит более 31 кг",
    l_Net_weight: "Вес нетто",
    l_Overall_weight: "Общий вес",
    l_History: "История",

    l_Updated_time: "Время изменения",
    l_Order_count: "Количество заказов",
    l_Unknown: "Неизвестно",
    l_Mini_batch_count: "Количество mini batch",
    l_Return_order: "Возврат заказа",
    l_Change_branch: "Изменить филиал",
    l_Image: "Изображение",
    l_Search_in_shelf: "Искать на полке",
    l_Translation: "Перевод",
    l_Primary_language: "Основной язык",
    l_Content: "Содержание",
    l_Translated_language: "Переведенный язык",
    l_Check_the_category_of_KTZ: "Проверить категорию KTZ",
    l_There_is_KTZ_category: "Имеется категория KTZ",
    l_There_is_no_category_KTZ: "Категория KTZ отсутствует",
    l_Statistic: "Статистика",
    l_Are_you_sure_of_the_correctness_of_the_data: "Вы уверены в правильности данных?",
    l_China_warehouse: "Китайский склад",
    l_Sorting: "Сортировка",
    l_Role: "Роль",
    l_Stay_branch_day: "Дни нахождения в филиале (мин)",
    l_Min_weight: "Вес (мин)",
    l_Menu: "Меню",
    l_Left_from_Almaty: "Выехал из Алматы",
    l_Left_from_China: "Выехал из Китая",
    l_Almaty_warehouse_received: "Алматинский склад получил",
    l_China_warehouse_received: "Китайский склад получил",
    l_Jpid_build_by_minibatch: "Jpid, созданный minibatch",
    l_Weighed_id: "Взвешенный id",
    l_Close: "Закрыть",
    l_Article: "Статья",
    l_Employees: "Сотрудники",

    l_Visibility: "Видимость",
    l_Can_view: "Может увидеть",
    l_Admin_worker: "Рабочий администратор",
    l_Web: "Веб",
    l_General: "Общий",
    l_Door_to_door: "От двери до двери",
    l_Pending_orders: "ожидающие заказы",
    l_In_delivery: "доставка",
    l_Received: "получено",
    l_Reject: "Отклонить",
    l_Pending: "待处理",
    l_Supervision: "Супервизия",
    l_Language: "Язык",
    l_Agent: "Агент",
    l_My_Statistics: "Моя статистика",
    l_Count_Stats: "Статистика подсчета",
    l_Recharge_the_balance: "Пополнить баланс",
    l_Transactions: "Транзакции",
    l_Transaction_type: "Вид транзакции",
    l_Old_password: "Старый пароль",
    l_Reset: "Очистить",
    l_Transactions_detail: "Детали транзакций",
    l_Clear: "Очистить",
    l_Created_time: "Время создания",
    l_Source_type: "Метод оплаты",
    l_Comment: "Комментарий",
    l_Admin_add: "Добавить админа",
    l_Username: "Пользователь",
    l_Datails: "Детали",
    l_Destination_JPID: "Получатель JPID",
    l_Source_ID: "Отравитель ID",
    l_Clear_account: "Очистить баланс",
    l_See_more: "Увидеть больше",
    l_List: "Список",
    l_Code: "Код",
    l_Payment_time: "Дата платежа",
    l_Payment_list: "Список платежей",
    l_OrderPay: "Оплата заказа",
    l_AddFunds: "Добавить средства",
    l_Account2account_translate: "Перевод между счетами",
    l_Account2account_add_bonus: "Добавление бонуса",
    l_Refund: "Возврат денег на счет",
    l_Single_plus: "Добавить деньги",
    l_Single_minus: "Убрать деньги(қате екенін білем:) )",
    l_Single_clear: "Кошелек очищен",
    l_Business_type: "Бизнес тип",
    l_Exchange_rate: "Курс обмена",
    l_Currency: "Валюта",
    l_Source: "Откуда",
    l_Destination: "Куда",
    l_Translate: "Перевод",
    l_Limit_type: "Лимит тип",
    l_Method_type: "Метод тип",
    l_Percent: "Процент",
    l_No_limit: "Безлимитный",
    l_Time_limit: "Ограниченный",
    l_Save: "Сохранить",
    l_Actions: "Акций",
    l_Recompile: "Переписовать",
    l_Account2account_course_pay: "Оплата курса",
    l_Sum: "Сумма",

    l_kaspi: "Kaspi",
    l_card: "Card",
    l_epay: "Epay",
    l_kazpost: "KazPost",
    l_pay_me: "Payme",
    l_freedom_pay: "Freedom Pay",
    l_tarlan: "Tarlan",
    l_click: "Click",
    l_uzum: "Uzum",
    l_mbank: "MBank",
    l_uzum_app: "Uzum App",
}
