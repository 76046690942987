export default {
    l_User_name: "用户名",
    l_User_password: "密码",
    l_Forgot_password: "找回密码",
    l_Login: "登录",
    l_Sign_out: "退出",
    l_EmailorPasswordisWrong: "邮箱或密码不正确",
    l_User: "用户",
    l_Payments: "付款",
    l_Promocodes: "促销代码",
    l_Messages: "消息",
    l_Consolidations: "合并",
    l_Courier_Orders: "快递订单",
    l_Almaty: "阿拉木图",
    l_Name: "名称",
    l_Add: "添加",
    l_Action: "操作",
    l_Update: "更新",
    l_Edit: "编辑",
    l_Submit: "提交",
    l_Cancel: "取消",
    l_Are_you_sure_delete_this_task: "你确定要删除这个任务吗？",
    l_Yes: "是",
    l_No: "否",
    l_Tracking_number: "单号",
    l_Internal_Tracking_number: "内部追踪号",
    l_Actual_Price: "实际价格",
    l_Estimated_price: "预估价格",
    l_Actual_weight: "实际重量",
    l_kg: "公斤",
    l_Estimated_weight: "预估重量",
    l_Delivery_country: "送货国家",
    l_Delivery_type: "配送类型",
    l_Address: "地址",
    l_Execution_status: "执行状态",
    l_Cargo: "货物",
    l_Sign_up: "注册",
    l_Next: "下一步",
    l_Verify_code: "验证码",
    l_Set_password: "设置密码",
    l_Confirm_password: "确认密码",
    l_Get_SMS: "获取短信",
    l_Get_the_verification_code_first: "先获取验证码",
    l_Confirm_password_error: "密码确认错误",
    l_Phone_number: "电话号码",
    l_Password: "密码",
    l_Wrong_password_format: "密码格式错误",
    l_Please_enter_phone_number: "请输入电话号码",
    l_Please_enter_sms: "请输入短信",
    l_My_addresses: "我的地址",
    l_My_parcels: "我的包裹",
    l_Buy_instead_of_me: "代购",
    l_Personal_data: "个人资料",
    l_Update_password: "修改密码",
    l_Old_Password: "旧密码",
    l_New_Password: "新密码",
    l_Successed: "成功",
    l_Personal_information: "个人信息",
    l_User_list: "用户列表",
    l_Email: "电子邮件",
    l_Add_user: "添加用户",
    l_Full_name: "姓名",
    l_IIN: "IIN",
    l_ID_number: "身份证号码",
    l_Issuing_authority: "发证机构",
    l_Issue_date: "发行日期",
    l_Validity: "有效性",
    l_Delivery_address: "收货地址",
    l_Town: "地区-市/区",
    l_Street: "街道",
    l_House: "房号",
    l_Postcode: "邮政编码",
    l_Identity_card: "身份证",
    l_Facial: "正面",
    l_Negotiable: "背面",
    l_Draw_a_signature: "画签名",
    l_Or_download_the_signed_contract: "或者下载已签署的合同",
    l_Note: "备注",
    l_Precautions_for_uploading_pictures: "附件必须质量好。图像文件不得超过 1MB。",
    l_Click_or_drag_ile_to_this_area_to_upload: "点击或拖动文件到此区域上传",
    l_My_order: "我的订单",
    l_Track_code: "运单号",
    l_Recipient: "收件人",
    l_Cargo_name: "货物名称",
    l_Declared_price: "申报价格",
    l_Date: "日期",
    l_Delivery_cost: "运费",
    l_Dispatch_country: "发货国家",
    l_Delivery_method: "配送方式",
    l_Order: "订单",
    l_Recipient_data: "收件人数据",
    l_Shipping_country: "发货国家",
    l_Transportation_method: "运输方式",
    l_Price: "价格",
    l_Quantity: "数量",
    l_Total_cost: "总费用",
    l_Category: "类别",
    l_Weapons_and_explosive_materials: "武器和爆炸材料",
    l_Alcoholic_drinks: "含酒精的饮料",
    l_Sprays_and_aerosols: "喷雾剂和气雾剂",
    l_Medicines_without_a_doctors_prescription: "没有医生处方的药物",
    l_radioactive_elements: "放射性元素",
    l_Animals_and_plants: "动物和植物",
    l_ALL_RIGHTS_RESERVED: "保留所有权利",
    l_Contact: "联系人",
    l_About_us: "关于我们",
    l_The_shops: "商店",
    l_How_it_works: "它是如何工作的？",
    l_Rates: "利率",
    l_Frequently_asked_Questions: "常见问题",
    l_Prohibited_Items: "违禁物品",
    l_Privacy_Policy: "隐私政策",
    l_Weight: "重量",
    l_Declaration: "声明",
    l_Pay: "支付",
    l_Provinces: "省份",
    l_Country: "国家",
    l_Full_address: "完整地址",
    l_MY_ADDRESS_IN_CHINA_FOR_AUTO_DELIVERY: "中国仓库地址",
    l_Add_product: "添加货物",
    l_Please_enter_quantity: "请输入数量",
    l_The_maximum_number_is: "最大数量是",
    l_China: "中国",
    l_Auto_delivery: "汽车送货",
    l_To_avoid_problems_during_customs_clearance_please_enter_a_detailed_description_of_the_product_name_in_Russian: "为避免清关过程中出现问题，请用俄语提供产品名称的详细描述。",
    l_Later_confirm: "稍后确认",
    l_Confirm: "确认",
    l_Succeed: "操作成功",
    l_Provide_a_link_to_the_product_we_will_buy_and_bring_it_for_you: "发送产品链接，我们会为您购买并送货！",
    l_Cargo_link: "货物链接",
    l_Additional_comment: "补充说明",
    l_Are_you_willing_to_buy_if_the_price_fluctuates_up_to_7: "如果价格波动高达 7%，你是否愿意购买？",
    l_size: "尺寸",
    l_Color: "颜色",
    l_Delivery_price: "运输费用",
    l_Promo_codes: "促销代码",
    l_Whether_the_payment_is_completed: "支付是否完成？",
    l_Completed: "完成",
    l_Not_completed: "未完成",
    l_Image_must_smaller_than_3MB: "图片必须小于 3MB！",
    l_Image_must_smaller_than_2MB: "图片必须小于 2MB！",
    l_Image_format_error: "图片格式错误！",

    l_Analytics: "分析",
    l_Status: "状态",
    l_Order_id: "订单编号",
    l_Traking_number: "运单号",
    l_Amount: "金额",
    l_Payment_metehod: "支付方式",
    l_Payment: "付款",
    l_Recipients: "收件人",
    l_First_name: "名字",
    l_Last_name: "姓氏",
    l_Type: "类型",
    l_Client: "客户端",
    l_Admin: "管理员",
    l_Last_login: "上次登录",
    l_Register: "注册",
    l_Verified: "已验证",
    l_Not_verified: "验证失败",
    l_All: "全部",
    l_Search: "搜索",
    l_Statuses: "状态",
    l_Start_country: "发货国家",
    l_End_country: "送货国家",
    l_Sort: "排序",
    l_Add_time: "添加时间",
    l_Update_time: "更新时间",
    l_Ascending: "升序",
    l_Descending: "降序",
    l_Create_time: "创建时间",
    l_Message: "留言",
    l_Send_message: "发送消息",
    l_Internal_tracking_number: "内部追踪号",
    l_Shopping_instead: "代替购买",
    l_Size: "尺寸",
    l_Description: "描述",
    l_Link: "链接",
    l_City: "城市",
    l_All_cargo: "所有货物",
    l_Volume: "体积",
    l_Height: "身高",
    l_Paid: "已支付",
    l_Unpaid: "未付",
    l_Declaration_verify: "声明验证",
    l_Update_date: "更新日期",
    l_Add_date: "添加日期",
    l_Add_to_consolidation: "添加到合并",
    l_Consolidation: "合并",
    l_Consolidation_id: "合并编号",
    l_Add_type: "添加类型",
    l_Add_new: "新增",
    l_Contractor: "承包商",
    l_Selected_order_quantity: "选择订单数量",
    l_Flight_number: "航班号",
    l_Execution_date: "执行日期",
    l_Document: "文档",
    l_Add_order: "添加订单",
    l_Can_choose_to_consolidation: "可以选择合并",
    l_Amount_paid: "支付金额",
    l_Payment_method: "支付方式",
    l_Card: "银行卡",
    l_Cash: "现金",
    l_External_ID: "外部 ID",
    l_Verification_succeeded: "验证成功",
    l_Verification_failed: "验证失败",
    l_Car_number: "车号",
    l_trailer_car_number: "拖车车号",
    l_Foreign_warehouse: "国外仓库",
    l_Almaty_warehouse: "阿拉木图仓库",
    l_Info: "信息",
    l_Payment_amount: "支付金额",
    l_Automatic_storage_filter_cleared_when_refresh: "自动存储过滤，刷新时清除",
    l_Not_found: "未找到",

    l_Contract_template: "合同模板",
    l_File_format_error: "文件格式错误！",
    l_Back_home_page: "回到首页",
    l_Picked_up: "捡起",
    l_Not_picked_up: "未拾取",
    l_Payment_status: "支付状态",
    l_Ready_to_ship: "Ready_to_ship",
    l_Belongs_category: "父级类别",
    l_File: "文件",
    l_Poster: "海报",
    l_format_error: "格式错误",
    l_TN_CODE: "TNVED代码",
    l_Other: "其他",
    l_Company: "公司",
    l_Order_tracking: "订单查询",
    l_Not_fount: "未找到",
    l_Submit_and_print_label: "提交并打印标签",
    l_Are_you_sure_update_password: "你确定更新密码吗？",
    l_Print_: "打印",
    l_Operator: "操作人",
    l_Russian_language: "俄语",
    l_Print_label: "打印标签",
    l_Warehouse: "仓库",

    l_Checking: "审核中",
    l_Verification_failed_description: "失败原因",
    l_App_version: "应用版本",
    l_App_version_title: "版本标题",
    l_App_version_content: "版本内容",
    l_App_version_system: "应用系统",
    l_Version: "版本号",
    l_Force_update: "需要强制更新吗？",
    l_Published: "已发布",
    l_Unpublished: "未发布",
    l_Select: "选择",
    l_Gross_weight: "毛重",
    l_Join_warehouse: "入库",
    l_Batch_number: "批次号",
    l_Unclaimed_order: "无主件",
    l_China_batch: "CN batch",
    l_Reject_order: "拒签订单",
    l_Mini_batch: "Mini batch",
    l_Kazakhstan_batch: "KZ batch",
    l_Branch_warehouse: "自提点",
    l_Driver: "司机",
    l_Dest_station: "目标站",
    l_Batch: "Batch",
    l_Shelf: "货架",
    l_Add_to_shelf: "添加到货架",
    l_Data: "数据",
    l_Settings: "设置",
    l_Creator: "创建者",
    l_Shelf_number: "货架号",
    l_Sorting_center: "分拣中心",
    l_Pick_up: "用户签收",
    l_Scan: "扫描",
    l_Last_six_digits_of_mobile_number: "手机号后六位",
    l_Branch_batch: "Branch batch",
    l_Arrived_to_branch: "到达自提点",
    l_Arrived: "到达",
    l_Export_excel: "导出 Excel",
    l_Work_time: "工作时间",
    l_Manager: "管理员",
    l_Province: "省",
    l_Remark: "备注",
    l_Start: "开始",
    l_End: "结束",
    l_Do_not_add_randomly: "不要随意添加！",
    l_Find_branch: "查找自提点",
    l_Refresh: "刷新",
    l_Has_photo: "有照片",
    l_Valid_time: "有效时间",
    l_Visible_state: "可见状态",
    l_File_update_time: "文件更新时间",
    l_Time: "时间",
    l_Info_api: "信息 API",
    l_Created: "已创建",
    l_Group_payment: "合并支付",
    l_Permission: "权限",
    l_System: "系统",
    l_Road_type: "路型",
    l_Official: "正规",
    l_Commerce: "商业",
    l_Recommended_shelf: "推荐货架",
    l_Amount_payable_by_the_client: "客户应付金额",
    l_Payment_completed: "付款完成",
    l_Error_batch: " 异常batch",
    l_Error_order: "异常订单",
    l_Error_mini_batch: "异常 mini batch",
    l_Work: "工作",

    l_China_logistics: "中国物流",
    l_Receiver: "接收者",
    l_Transport_day: "运输天数",
    l_Day: "天",
    l_tel_merge: "合并手机号",
    l_Abnormal_order: "异常订单",
    l_Customs: "海关",
    l_Contract: "合同",
    l_Invoice: "Invoice",
    l_Filter: "筛选",
    l_CN_category_id: "CN类别ID",
    l_Prohibited_catgegories: "禁止类",
    l_Error: "内部系统错误！请截图发送IT部门的同事。我们尽快解决",
    l_Those_whose_weight_is_over_31_kg: "重量超过了31公斤",
    l_The_price_is_more_than_1000: "总价超过了1000$",
    l_Price_over_1000_and_weight_over_31_kg: "价格超过了1000$并且重量超过了31kg",
    l_Net_weight: "净重",
    l_Overall_weight: "毛重",
    l_History: "历史记录",

    l_Updated_time: "更新时间",
    l_Order_count: "订单数量",
    l_Unknown: "未知",
    l_Mini_batch_count: "Mini batch 数量",
    l_Return_order: "退回订单",
    l_Change_branch: "更改自提点",
    l_Image: "图片",
    l_Search_in_shelf: "架子中搜索",
    l_Translation: "翻译",
    l_Primary_language: "默认语言",
    l_Content: "内容",
    l_Translated_language: "翻译目标语言",
    l_Check_the_category_of_KTZ: "检查哈铁路目录",
    l_There_is_KTZ_category: "哈铁路目录存在",
    l_There_is_no_category_KTZ: "哈铁路目录不存在",
    l_Statistic: "统计",

    l_Are_you_sure_of_the_correctness_of_the_data: "你确定数据正确吗？",
    l_China_warehouse: "中国仓库",
    l_Sorting: "分拣",
    l_Role: "角色",
    l_Stay_branch_day: "呆自提点天数（Min）",
    l_Min_weight: "重量 (Min)",
    l_Menu: "菜单",
    l_Left_from_Almaty: "他离开阿拉木图了",
    l_Left_from_China: "出中国",
    l_Almaty_warehouse_received: "阿拉木图仓库已受理",
    l_China_warehouse_received: "中国仓库已收到",
    l_Jpid_build_by_minibatch: "通过minibatch构建的jpid",
    l_Weighed_id: "称重操作员ID",
    l_Close: "关闭",
    l_Article: "文章",
    l_Employees: "雇员",

    l_Visibility: "是否开启",
    l_Can_view: "谁能看",
    l_Admin_worker: "管理员工作者",
    l_Web: "Web",
    l_Category_language: "类别语言",
    l_General: "总",
    l_Door_to_door: "送货上门",
    l_Pending_orders: "待处理订单",
    l_In_delivery: "正在送货",
    l_Received: "送达",
    l_Reject: "拒绝",
    l_Supervision: "监督",
    l_Language: "语言",
    l_Agent: "代理",
    l_My_Statistics: "我的统计",
    l_Count_Stats: "计数统计",
    l_Transactions: "交易",
    l_Recharge_the_balance: "充值您的余额",
    l_Transaction_type: "交易类型",
    l_Old_password: "旧密码",
    l_Reset: "重置",
    l_Transactions_detail: "交易详情",
    l_Clear: "清除",
    l_Created_time: "创建时间",
    l_See_more: "查看更多",
    l_Admin_add: "添加管理员",
    l_List: "列表",

    l_kaspi: "Kaspi",
    l_card: "Card",
    l_epay: "Epay",
    l_kazpost: "KazPost",
    l_pay_me: "Payme",
    l_freedom_pay: "Freedom Pay",
    l_tarlan: "Tarlan",
    l_click: "Click",
    l_uzum: "Uzum",
    l_mbank: "MBank",
    l_uzum_app: "Uzum App",
}
